export default {
	// ==============意见建议==============================
	opinion:{
		意见建议:"의견및제안",
		您的昵称:"닉네임",
		您的电话:"전화번호",
		提交:"제출",
		南京天生桥景区官方网站:"난징천생교관광지공식웹사이트",
		地址:"주소",
		江苏省南京市:"장쑤성난징시",
		邮编:"우편번호",
		咨询电话:"문의전화",
		救援电话:"구조전화",
		投诉电话:"불만전화",
		传真:"팩스",
		网址:"웹주소",		
	},
	// ==============底部==============================
	foot:{
		景区咨询电话:"관광지문의전화",
		景区救援电话:"관광지구조전화",
		景区投诉电话:"관광지불만전화",
		客服邮箱:"고객서비스이메일",
		网站备案号:"웹사이트등록번호",
		版权:"저작권",
		推荐景点:"추천명소",
		天生桥:"천생교",
		胭脂河:"연지강",
		胭脂峡:"연지협",
		隐秀峡:"은수협",
		联盟景区:"연합관광지",
		天生桥景区:"천생교관광지",
		景区信息:"관광지정보",
		最新公告:"최신공지",	
		营业时间:"영업시간",
		交通信息:"교통정보",
		车位大屏:"주차장디스플레이화면",
		返回顶部:"맨위로돌아가기",	
	},
	// ==============天气==============================
	location: {
		当前位置: "현재위치",
		首页:"첫 페이지",
	},
	// ==============天气==============================
	weather: {
		最大日承载量: "최대일수용량",
		预警日承载量: "경고일수용량",
		最大瞬时承载量: "최대순간수용량",
		合理日承载量: "합리적일수용량",
		温度: "온도",
		体感温度: "체감온도",
		风速: "풍속",
		湿度: "습도",
		风向: "풍향",
		能见度: "가시거리",
		人次:"연인원",
		秒:"초",
		级:"수준",
		空气质量:"공기 질",
	},

	// ==============菜单==============================
	navHome: '관광지홈페이지',
	menu: [{
			"value": "1",
			"label": "관광지개요", //景区概况
			"img": null,
			"levels": 1,
			"path": "ItemChannelTxtImg",
			"href": null,
			"children": [{
					"value": "16",
					"label": "역사연혁", //历史沿革
					"img": null,
					"levels": 2,
					"path": "ItemClassTxt",
					"href": null,
					"children": null
				},
				{
					"value": "12",
					"label": "전자가이드", //电子导览
					"img": null,
					"levels": 2,
					"path": "",
					"href": null,
					"children": null
				},
				{
					"value": "11",
					"label": "가상투어", //虚拟旅游
					"img": null,
					"levels": 1,
					"path": null,
					"href": "https://720.z2ww.com/dist/#/previewwidthoutmodel?id=2d1c8f10-d0ce-424b-9f5e-325be542944f",
					"children": null
				}
			]
		},
		{
			"value": "2",
			"label": "가이드투어", //导游导览
			"img": null,
			"levels": 1,
			"path": "ListChannelTxtImg",
			"href": null,
			"children": [{
					"value": "10",
					"label": "추천명소", //景点推荐
					"img": null,
					"levels": 1,
					"path": "ListClassImg",
					"href": null,
					"children": null
				},
				{
					"value": "13",
					"label": "공지사항", //通知公告
					"img": null,
					"levels": 2,
					"path": null,
					"href": null,
					"children": null
				},
				{
					"value": "14",
					"label": "작업동향", //工作动态
					"img": null,
					"levels": 2,
					"path": null,
					"href": null,
					"children": null
				},
				{
					"value": "15",
					"label": "행사예고", //活动预告
					"img": null,
					"levels": 2,
					"path": "ClassPublicImgSummany",
					"href": null,
					"children": null
				}
			]
		},
		{
			"value": "3",
			"label": "천연경치", //天生美景
			"img": null,
			"levels": 1,
			"path": "ChannelPrivateImg",
			"href": null,
			"children": [{
					"value": "17",
					"label": "비디오감상", //视频赏析
					"img": null,
					"levels": 2,
					"path": "NewsVoideo",
					"href": null,
					"children": null
				},
				{
					"value": "18",
					"label": "하이라이트순간", //精彩时刻
					"img": null,
					"levels": 2,
					"path": "NewsImage",
					"href": null,
					"children": null
				},
				{
					"value": "19",
					"label": "관광지풍경", //景区风光
					"img": null,
					"levels": 2,
					"path": "NewsImage",
					"href": null,
					"children": null
				}
			]
		},
		{
			"value": "4",
			"label": "여행가이드", //旅游攻略
			"img": null,
			"levels": 1,
			"path": "ChannelPrivateListImg",
			"href": null,
			"children": [{
					"value": "20",
					"label": "추천노선", //游线推荐
					"img": null,
					"levels": 2,
					"path": "NewsSummary1",
					"href": null,
					"children": null
				},
				{
					"value": "21",
					"label": "제품추천", //产品推荐
					"img": null,
					"levels": 2,
					"path": "NewsSummary1",
					"href": null,
					"children": null
				},
				{
					"value": "22",
					"label": "음식추천", //美食推荐
					"img": null,
					"levels": 2,
					"path": "NewsSummary1",
					"href": null,
					"children": null
				},
				{
					"value": "23",
					"label": "문화제품추천", //文创推荐
					"img": null,
					"levels": 2,
					"path": "NewsSummary1",
					"href": null,
					"children": null
				}
			]
		},
		{
			"value": "5",
			"label": "서비스정보", //服务信息
			"img": null,
			"levels": 1,
			"path": "ChannelParivateTxt",
			"href": null,
			"children": [{
					"value": "25",
					"label": "교통", //交通出行
					"img": null,
					"levels": 2,
					"path": null,
					"href": null,
					"children": null
				},
				{
					"value": "26",
					"label": "투자협력", //招商合作
					"img": null,
					"levels": 2,
					"path": "ItemClassTxt",
					"href": null,
					"children": null
				}
			]
		},
		{
			"value": "6",
			"label": "온라인예약", //在线预定
			"img": null,
			"levels": 1,
			"path": null,
			"href": null,
			"children": null
		},
		{
			"value": "7",
			"label": "고객서비스", //客户服务
			"img": null,
			"levels": 1,
			"path": "ChannelParivateTxt1",
			"href": null,
			"children": null
		}
	],

}